main {
  padding-top: 100px;
  padding-bottom: 100px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}

.text-orange {
  color: #f36c0a;
  font-size: 1.2em;
}

/* buttons */
.btn {
  border-radius: 2px;
}

.btn-secondary {
  color: #fff;
  background-color: #f36c0a;
  border-color: #f36c0a;

  &:hover {
    color: #fff;
    background-color: #f38231;
    border-color: #f38231;
  }
}

.btn-reset-password {
  padding-left: 10px;
  padding-right: 10px;
}

.btn-reset-pin {
  padding-left: 10px;
  padding-right: 10px;
}

/* login page */
.cms-login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url("/resources/images/login-background.jpg") center center cover;
}

.cms-login-form {
  width: 315px;
  margin: 0 auto;
  padding: 1.5em;
  color: #fff;
  background-color: rgba(8, 34, 71, 0.8);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.24);
  transition: box-shadow 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.no-bg {
    background-color: rgb(8, 34, 71);
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  .btn-link {
    color: #999;
  }

  img {
    margin-bottom: 0.5em;
  }
}

label,
input,
button {
  transition: all 0.3s cubic-bezier(0.8, 0, 0.24, 1);
}

/*Form control global changes*/
.form-group {
  position: relative;
}

.form__input--line {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin-bottom: 2em;
  color: #fff;
  font-size: 1.2em;
  border-bottom-width: 2px;
  cursor: pointer;

  &:focus {
    box-shadow: none;

    &:valid {
      border-bottom-color: #5677fc;
    }

    &::-webkit-input-placeholder {
      opacity: 1;
    }

    ~ .form__label--float {
      top: -1em;
      opacity: 1;
      font-weight: normal;
      color: #5677fc;
      font-size: 80%;
    }
  }

  &:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
  }

  &:invalid {
    border-bottom-color: red;

    ~ .form__label--float {
      color: red;
    }

    ~ .form__error {
      opacity: 1;
    }
  }

  &:not(:invalid):focus ~ .form__helper {
    opacity: 1;
  }

  &:hover {
    border-bottom-color: #f36c0a;
  }

  &[data-has-value="true"] {
    &:not(:focus) ~ .form__label--float {
      top: -1em;
      font-size: 80%;
    }

    &:invalid ~ .form__label--float-validate:after,
    &:valid ~ .form__label--float-validate:after {
      font-family: FontAwesome;
      opacity: 1;
      font-size: 100%;
      display: inline-block;
      padding-left: 5px;
      position: absolute;
      top: 0.5em;
      right: 0;
      z-index: 1;
    }

    &:valid ~ .form__label--float-validate:after {
      content: "\f00c";
      color: #5677fc;
    }

    &:invalid ~ .form__label--float-validate:after {
      content: "\f00d";
      color: red;
    }
  }

  /* chrome autofill */
  &:-webkit-autofill {
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: transparent !important;
      transition: background-color 9999s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }

    ~ .form__label--float {
      top: -1em;
      font-size: 80%;
    }
  }
}

.confirm-pw .confirm-pin {
  &.invalid {
    .form__input--line,
    .form__input--line:focus {
      border-bottom-color: red;
    }

    .form__label--float {
      color: red;
    }

    .form__input--line[data-has-value="true"]:valid
      ~ .form__label--float-validate:after {
      content: "\f00d";
      color: red;
    }

    .form__helper {
      opacity: 0 !important;
    }

    .form__error {
      opacity: 1 !important;
    }
  }

  &:not(.invalid) {
    .form__input--line:focus {
      border-bottom-color: #5677fc;

      ~ .form__helper {
        opacity: 1;
      }
    }

    .form__input--line[data-has-value="true"]:valid
      ~ .form__label--float-validate:after {
      content: "\f00c";
      color: #5677fc;
    }

    .form__error {
      opacity: 0;
    }
  }
}

.form__helper {
  opacity: 0;
  top: 3.25em;
  position: absolute;
  font-size: 80%;
  color: #999;
}

.form__error {
  opacity: 0;
  top: 3.25em;
  position: absolute;
  font-size: 80%;
  color: red;
}

.form__label--float {
  position: absolute;
  pointer-events: none;
  top: 0.2em;
  font-weight: normal;
  color: #999;
  font-size: 1.2em;
}

.float-validate:after {
  z-index: 999;
  cursor: pointer;
}

.ValidationSummaryContent ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: auto;
}

/* account profile */
#accountProfile {
  p {
    margin-bottom: 0;

    &.small {
      color: rgb(8, 34, 71);
    }
  }

  .account-info {
    margin-bottom: 1.2em;
  }
}

/* login provider buttons*/
.text-divider {
  position: relative;
  overflow: hidden;
  text-align: center;
  font-size: 20px;
  color: #999;

  &:before,
  &:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 30%;
    height: 1px;
    content: "\a0";
    background-color: #999;
  }

  &:before {
    margin-left: -35%;
    text-align: right;
  }

  &:after {
    margin-left: 5%;
    text-align: left;
  }
}

.major-provider-button {
  width: 100%;
  height: 35px;
  cursor: pointer;
  margin-top: 7px;
}

.minor-provider-button {
  width: 45px;
  height: 35px;
  cursor: pointer;
  margin-top: 18px;
}

.space-children-evenly {
  display: flex;
  justify-content: space-between;
}

.fabutton {
  background: none;
  padding: 0;
  border: none;
}

.provider-panel {
  padding-left: 0;
  padding-right: 0;
}

#providerSelect {
  width: 200px;
  display: inline;
}

.flashing-row {
  transition: all 1s ease-in-out;

  &.active {
    background-color: #f5f5f5;
  }
}

.error_message {
  border-radius: 2px;
  margin-top: 30px;
  box-shadow: 0px 1px 5px #81818199;
  color: #ee2424;

  p {
    margin: 10px;
  }

  ul {
    padding-left: 10px;
    padding-right: 10px;

    li {
      list-style-type: none;

      &:before {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        vertical-align: middle;
        padding-right: 5px;
      }
    }
  }
}